<template>
  <section id="dashboard-ecommerce">
    <!-- header -->
    <header
      class="header-bg tw-px-7 tw-pt-[49.43px] -tw-z-10 tw-absolute tw-left-0 tw-right-0 tw-top-[82px] tw-h-[195px]"
    >
      <h3
        class="tw-font-semibold tw-text-white tw-text-[34px] tw-leading-[48px]"
      >
        Hello {{ "Admin" }}!
      </h3>
      <p class="tw-text-white tw-leading-6">
        Perbanyak bank soal dan nikmati pengalamannya!
      </p>
    </header>

    <!-- Stat -->
    <div
      class="tw-flex min-[900px]:tw-overflow-hidden tw-px-4 tw-overflow-scroll shadow tw-mt-[135px] tw-w-full tw-rounded-[15px] tw-py-[47px] lg:tw-justify-center tw-justify-start tw-gap-[40px] tw-bg-white"
    >
      <div
        class="tw-rounded-[15px] tw-px-[30px] tw-py-[16px] shadow tw-w-[290px] tw-h-[162px]"
      >
        <div class="tw-flex tw-mb-5 tw-justify-between">
          <div>
            <p>Total User</p>

            <h3
              class="tw-font-semibold tw-w-[150px] value tw-text-[20px] tw-leading-[48px]"
            >
              {{ user.total_users || 0 }}
            </h3>
          </div>

          <div
            class="tw-w-[60px] tw tw-flex tw-justify-center tw-items-center tw-bg-[#8280FF] tw-bg-opacity-20 tw-rounded-[25px] tw-h-[60px]"
          >
            <imgccccvcvf
              class="tw-w-[3wpx] tw-h-[24px]"
              src="/assets/images/dashboard/people.png"
              alt="people"
            />
          </div>
        </div>

        <div class="tw-flex tw-gap-2 tw-leading-6">
          <img
            :src="`/assets/images/dashboard/trend-${
              user.is_today_new_users_greater ? 'up' : 'down'
            }.png`"
            alt="trend"
          />
          <p class="tw-mb-0">
            <span
              :class="`${false ? 'tw-text-[#00B69B]' : 'tw-text-[#F93C65]'}`"
              >{{ user.percent_change_new_users }}%</span
            >
            {{ user.is_today_new_users_greater ? "Up" : "Down" }}
            from yesterday
          </p>
        </div>
      </div>

      <div
        class="tw-rounded-[15px] tw-px-[30px] tw-py-[16px] shadow tw-w-[290px] tw-h-[162px]"
      >
        <div class="tw-flex tw-mb-5 tw-justify-between">
          <div>
            <p>Total Transaksi</p>

            <h3
              class="tw-font-semibold tw-w-[150px] value tw-text-[20px] tw-leading-[48px]"
            >
              <!-- {{ order.total_orders || 0 }} -->
              {{ 48237 }}
            </h3>
          </div>

          <div
            class="tw-w-[60px] tw tw-flex tw-justify-center tw-items-center tw-bg-[#FEC53D] tw-bg-opacity-20 tw-rounded-[25px] tw-h-[60px]"
          >
            <img
              class="tw-w-[3wpx] tw-h-[24px]"
              src="/assets/images/dashboard/cube.png"
              alt="people"
            />
          </div>
        </div>

        <div class="tw-flex tw-gap-2 tw-leading-6">
          <img
            :src="`/assets/images/dashboard/trend-${
              order.is_this_week_new_orders_greater ? 'up' : 'down'
            }.png`"
            alt="trend"
          />
          <p class="tw-mb-0">
            <span
              :class="`${
                order.is_this_week_new_orders_greater
                  ? 'tw-text-[#00B69B]'
                  : 'tw-text-[#F93C65]'
              }`"
              >{{ order.percent_change_new_orders }}</span
            >
            {{ order.is_this_week_new_orders_greater ? "Up" : "Down" }} from
            yesterday
          </p>
        </div>
      </div>

      <div
        class="tw-rounded-[15px] tw-px-[30px] tw-py-[16px] shadow tw-w-[290px] tw-h-[162px]"
      >
        <div class="tw-flex tw-mb-5 tw-justify-between">
          <div>
            <p>Total Sales</p>

            <h3
              class="tw-font-semibold tw-w-[150px] tw-text-[20px] tw-leading-[48px]"
            >
              <!-- {{ rupiah(sales.total_sales) }} -->
              {{ rupiah(213625000) }}
            </h3>
          </div>

          <div
            class="tw-w-[60px] tw tw-flex tw-justify-center tw-items-center tw-bg-[#4AD991] tw-bg-opacity-20 tw-rounded-[25px] tw-h-[60px]"
          >
            <img
              class="tw-w-[3wpx] tw-h-[24px]"
              src="/assets/images/dashboard/chart.png"
              alt="people"
            />
          </div>
        </div>

        <div class="tw-flex tw-gap-2 tw-leading-6">
          <!-- <img
            :src="`/assets/images/dashboard/trend-${
              sales.is_today_new_sales_greater ? 'up' : 'down'
            }.png`"
            alt="trend"
          /> -->
          <p class="tw-mb-0">
            <!-- <span
              :class="`${
                sales.is_today_new_sales_greater
                  ? 'tw-text-[#00B69B]'
                  : 'tw-text-[#F93C65]'
              }`"
              >{{ sales.percent_change_new_sales }}</span
            > -->
            Last 30 Days
          </p>
        </div>
      </div>
    </div>

    <!-- List TO -->
    <div class="tw-mt-[32px] tw-mb-[18px]">
      <div class="tw-flex tw-items-center tw-justify-between">
        <p class="tw-text-[24px] tw-font-semibold">
          Paket soal yang baru saja ditambahkan
        </p>

        <b-link
          class="tw-font-semibold tw-text-[16px]"
          href="https://1.envato.market/pixinvent_portfolio"
          >Lihat lainnya</b-link
        >
      </div>
    </div>

    <b-row>
      <b-col v-for="item in items" :key="item.id" sm="6" md="6" lg="4">
        <b-link>
          <card
            :title="item.nama"
            :jenis="item.jenis"
            :mataPelajaran="item.kategori_tryout"
            :updatedAt="formatDate(item.updated_at)"
          />
        </b-link>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BLink } from "bootstrap-vue";
import Card from "@/components/commons/Card.vue";
import { rupiah, formatDate } from "@core/utils/utils";

import repository from "@repofactory";
import { onMounted, ref } from "@vue/composition-api";
const repoDashboard = repository.get("DashboardRepository");

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    Card,
  },
  setup() {
    const toast = useToast();

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const user = ref({
      total_users: null,
      percent_change_new_users: null,
      is_today_new_users_greater: null,
    });

    const order = ref({
      total_orders: null,
      percent_change_new_orders: null,
      is_this_week_new_orders_greater: null,
    });

    const sales = ref({
      total_sales: null,
      total_sales: null,
      is_today_new_sales_greater: null,
    });

    const items = ref([]);

    const getStats = async () => {
      try {
        const res = await repoDashboard.get();
        user.value = res.data.data.stats.user;
        order.value = res.data.data.stats.order;
        sales.value = res.data.data.stats.sales;
        items.value = res.data.data.paket_tryouts;
      } catch (e) {
        if (e.response) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else if (e.request) {
          showToast(
            "Error",
            "Tidak bisa request data ke server",
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast("Error", e.message, "danger", "AlertTriangleIcon");
        }
      }
    };

    onMounted(async () => {
      getStats();
    });

    return {
      // State
      user,
      order,
      sales,
      items,

      // Method
      rupiah,
      getStats,
      formatDate,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

p {
  margin-bottom: 0;
}

.header-bg {
  background: url("/assets/images/dashboard/dashboard-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.shadow {
  box-shadow: 0px 10px 30px 0px #5022e20d;
}

.value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
