<template>
  <div
    :class="`container-shadow tw-w-[347.74px] tw-flex tw-flex-col tw-p-[21px] tw-mb-3 tw-min-h-[359.58px] tw-rounded-[13.65px] tw-bg-white`"
  >
    <!-- Thumbnail -->
    <div
      :style="{
        background: `url(${this.thumbnail || '/assets/images/dashboard/card/bg.png'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        
      }"
      :class="`tw-flex tw-justify-end tw-rounded-[13.65px] tw-h-[205px] tw-p-[12px] tw-w-full`"
    >
      <button
        class="tw-w-[34px] tw-h-[34px] tw-border-0 tw-bg-white tw-rounded-[50%]"
      >
        <feather-icon
          icon="Edit2Icon"
          size="18"
          :badgeClasses="'tw-text-[#5022E2]'"
        />
      </button>
    </div>

    <!-- Content -->
    <div class="tw-mt-[19px] tw-text-[#1B2559] tw-text-[16px]">
      <p class="tw-font-semibold">
        {{ this.title }}
      </p>

      <p class="tw-text-[#A3AED0] tw-mt-[5px] tw-mb-[19px]">
        {{ `Update : ${this.updatedAt}` }}
      </p>

      <!-- Badges -->
      <div class="tw-flex tw-gap-[12px]">
        <!-- Mata Pelajaran -->
        <div
          class="tw-rounded-[70px] tw-bg-[#5022E2] tw-py-[5px] tw-flex tw-px-[20px] tw-text-white"
        >
          {{ this.mataPelajaran }}
        </div>

        <!-- Jenis -->
        <div
          class="tw-rounded-[70px] tw-bg-[#5022E2] tw-py-[5px] tw-flex tw-px-[20px] tw-text-white"
        >
          {{ this.jenis }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { rupiah } from '@core/utils/utils'
import {
  BButton,
} from "bootstrap-vue";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default defineComponent({
    components: {
        BButton,
        FeatherIcon,
    },
    props: {
        thumbnail: String,
        title: String,
        mataPelajaran: String,
        jenis: String,
        updatedAt: String,
    },
    setup() {
        return {
            rupiah,
        }
    },
})
</script>

<style scoped>
.container-shadow {
  box-shadow: 0px 4px 40px 0px #0000001A;
}

p {
    margin-bottom: 0;
}
</style>
